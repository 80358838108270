import React from 'react'

import { SVG, Image } from './UI'
import Icons from '../graphics/icons'

import logo from '../brand/logo.png'

export default function Logo() {
    return (
        <SVG image={Icons.logo} primary contain/>
    )
}