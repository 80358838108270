export const filterByInclude = 'https://us-central1-smartag-60b70.cloudfunctions.net/filterByContain'

export const firebaseConfig = {
  apiKey: "AIzaSyDZqVYWXNr94TN9qtm7_J8RnghEJjzLoPY",
  authDomain: "smartag-60b70.firebaseapp.com",
  databaseURL: "https://smartag-60b70.firebaseio.com",
  projectId: "smartag-60b70",
  storageBucket: "smartag-60b70.appspot.com",
  messagingSenderId: "637417933081",
  appId: "1:637417933081:web:5f8c0f75294531c4aedd88"
}

export const storageUrl = 'https://firebasestorage.googleapis.com/v0/b/smartag-60b70.appspot.com/o/'